// React components
import Hero from '../../Hero'
import TextBlock from '../../TextBlock'
import Tabs from '../../Tabs'
import Swiper from '../../Swiper'
import RoundCard from '../../RoundCard'
import OfferCard from '../../OfferCard'
import Quotes from '../../Quotes'

// Artsy Fresnel
import { Media, MediaContextProvider } from '../../../utils/media'

// Styled Components
import { CareerWrapper, TabsWrapper } from './Career.styles'
import {
  MainContainer,
  ThinText,
  Divider,
  Title,
} from '../../../styled-components/shared/common'
import Spacer from '../../../styled-components/shared/Spacer'
import { Slide } from '../../Swiper/Swiper.styles'

// Constants
import { TABS_CAREER } from '../../../utils/constants'
import { Api } from '../../../types/api'
import useDictionaryLine from '../../../hooks/useDictionary'
import dictionary from '../../../utils/dictionary'
import { replaceByR, splitTextBy } from '../../../utils/dom'

type Props = {
  data: Api.CareerHome
  positions: Api.CareerJobType[]
}

const Career = ({ data, positions }: Props) => {
  const { hero, why, about, cards, socialResponsibility } = data.attributes
  const whyLabel = useDictionaryLine(dictionary.career.whyLabel)
  const aboutLabel = useDictionaryLine(dictionary.career.header.about)
  const offerLabel = useDictionaryLine(dictionary.career.whatWeOffer)
  const valuesLabel = useDictionaryLine(dictionary.career.header.values)
  const responsibleLabel1 = useDictionaryLine(
    dictionary.career.responsibleLabel1,
  )
  const responsibleLabel2 = useDictionaryLine(
    dictionary.career.responsibleLabel2,
  )
  const quotesTitle = useDictionaryLine(dictionary.career.quotesTitle)

  return (
    <CareerWrapper>
      <Hero
        page='career'
        title={hero.title}
        label={hero.buttons?.[0]?.title}
        url={hero.buttons?.[0]?.url}
        image={hero.image}
        positions={positions}
        description={hero.description}
      />
      <MediaContextProvider disableDynamicMediaQueries>
        <Media lessThan='lg'>
          <TabsWrapper>
            <Tabs tabs={TABS_CAREER} blur={true} />
          </TabsWrapper>
        </Media>
      </MediaContextProvider>
      <Spacer size={72} when={{ lg: 84 }} />
      <MainContainer>
        <TextBlock
          id='proc-ave'
          title={
            <>
              {splitTextBy({
                line: whyLabel.replace('?', ''),
                by: ' ',
                Left: ThinText,
              })}
            </>
          }
          button={true}
          content={<div dangerouslySetInnerHTML={{ __html: why }} />}
        />
      </MainContainer>
      <Spacer size={48} when={{ lg: 108 }} />
      <Swiper>
        {data?.attributes?.benefits.map(value => (
          <Slide key={value.title}>
            <RoundCard
              variant='white'
              text={value.title}
              icon={value.icon?.data?.attributes.url}
            />
          </Slide>
        ))}
      </Swiper>
      <Spacer size={48} when={{ lg: 120 }} />
      <Divider maxWidth='950px' center={true} />
      <Spacer size={48} when={{ lg: 120 }} />
      <MainContainer>
        <TextBlock
          id='o-nas'
          title={
            <>
              {splitTextBy({
                line: aboutLabel,
                by: ' ',
                Left: ThinText,
              })}
            </>
          }
          content={<div dangerouslySetInnerHTML={{ __html: about }} />}
        />
        <Spacer size={48} when={{ lg: 120 }} />
        <Divider maxWidth='950px' center={true} />
        <Spacer size={36} when={{ lg: 84 }} />
        <TextBlock
          id='co-nabizime'
          title={
            <>
              {splitTextBy({
                line: offerLabel,
                by: '|',
                removeSplitter: true,
                Left: ThinText,
              })}
            </>
          }
          content={
            <>
              <p>{data?.attributes?.benefitsPerex}</p>
            </>
          }
        />
        <Spacer size={30} when={{ lg: 36 }} />
      </MainContainer>
      {cards.map((card, index) => (
        <OfferCard
          key={card.title}
          title={card.title}
          description={card.content}
          media={card.media.data}
          textRight={Boolean(index % 2)}
        />
      ))}
      <Spacer size={60} when={{ lg: 120 }} />
      <MainContainer>
        <TextBlock
          id='firemni-kultura'
          title={
            <>
              {splitTextBy({
                line: valuesLabel,
                by: ' ',
                Left: ThinText,
              })}
            </>
          }
          content={
            <div
              dangerouslySetInnerHTML={{
                __html: data?.attributes?.companyValues,
              }}
            />
          }
        />
        <Spacer size={48} when={{ lg: 84 }} />
      </MainContainer>
      <Swiper>
        {data?.attributes?.values.map(value => (
          <Slide key={value.title}>
            <RoundCard
              variant='white'
              text={value.title}
              icon={value.icon.data?.attributes?.url}
            />
          </Slide>
        ))}
      </Swiper>
      <Spacer size={48} when={{ lg: 120 }} />
      <MainContainer>
        <Title type='red' align='center'>
          {quotesTitle}
        </Title>
        <Spacer size={48} when={{ lg: 60 }} />
        <Quotes data={data?.attributes?.citations} />
        <Spacer size={48} when={{ lg: 120 }} />
        <Divider maxWidth='950px' center={true} />
        <Spacer size={48} when={{ lg: 120 }} />
        <TextBlock
          id='spolecenska-odpovednost'
          perex={socialResponsibility.perex}
          title={
            <div style={{ maxWidth: '1044px' }}>
              {replaceByR(/\[.*\]/g)(
                a => a.replace(']', '').replace('[', ''),
                line => <ThinText key={line}>{line}</ThinText>,
              )(responsibleLabel1)}
              <br />
              {replaceByR(/\[.*\]/g)(
                a => a.replace(']', '').replace('[', ''),
                line => <ThinText key={line}>{line}</ThinText>,
              )(responsibleLabel2)}
            </div>
          }
          content={
            <div
              dangerouslySetInnerHTML={{ __html: socialResponsibility.content }}
            />
          }
        />
        <Spacer size={60} when={{ lg: 160 }} />
      </MainContainer>
    </CareerWrapper>
  )
}
export default Career
